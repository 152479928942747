<template>
  <div class="line-list container">
    <div class="title-box">
      <a href="/storage"
        ><font-awesome-icon icon="fa-solid fa-chevron-left" />내 저장소</a
      >
      <h2>네이버페이 결제내역</h2>
      <input type="hidden" id="naver_id" :value="naverUserId" />
      <b-button class="sharebtn" @click="onShare()"
        ><font-awesome-icon icon="fa-solid fa-arrow-up-from-bracket"
      /></b-button>
    </div>
    <div class="filter">
      <ul>
        <li @click="typeFilter($event, '')" class="types on">전체</li>
        <li @click="typeFilter($event, '배송중')" class="types ">배송중</li>
        <li @click="typeFilter($event, '구매확정완료')" class="types ">
          구매확정완료
        </li>
        <li @click="typeFilter($event, '결제완료')" class="types ">결제완료</li>
        <li @click="typeFilter($event, '결제취소')" class="types ">결제취소</li>
      </ul>
    </div>
    <div v-if="npay_data_count === 0 && users.length === 0" class="no-content">
      <h2>
        저장된 네이버페이 결제 내역이 없습니다!
        <br /><br /><br />
        <div class="text-center btn-get-data">
          <input
            class="btn btn-success"
            type="button"
            value="네이버페이 결제내역 불러오기"
            onclick="showAndroidToast( document.getElementById('naver_id').value,'https://www.naver.com')"
          />
        </div>
        <div class="text-center spiner" style="display: none">
          <b-spinner variant="info" label="Spinning"></b-spinner>
        </div>
        <br /><br /><br />
      </h2>
      <div></div>
    </div>
    <div v-else>
      <div class="msg-wrap">
        <div
          class="msg-board"
          v-for="user in filteredList"
          v-bind:key="user._id"
        >
          <!--      <img class="card-img-top" src="..." alt="Card image cap">-->

          <div class="msg-card image">
            <div class="thumb">
              <img :src="`${user.thumbnail}`" :alt="`${user.name}`" />
            </div>
            <div class="desc">
              <div class="pay-info">
                <span class="date">{{ user.date }}</span>
                <p class="status">{{ user.value }}</p>
              </div>
              <div class="prd-info">
                <p class="product">{{ user.text }}</p>
                <strong class="price">{{ user.price }}</strong>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="text-center btn-get-data">
        <input
          class="btn btn-success"
          type="button"
          value="네이버페이 결제내역 업데이트"
          onclick="showAndroidToast( document.getElementById('naver_id').value ,'https://www.naver.com')"
        />
      </div>
      <div class="text-center spiner" style="display: none">
        <b-spinner variant="info" label="Spinning"></b-spinner>
      </div>
    </div>
    <div class="datashare">
      <div class="bg" @click="onShare()"></div>
      <div class="share">
        <h4>데이터 공유하기</h4>
        <p>데이터를 공유하면 리워드를 받을 수 있어요!</p>
        <div class="title-btn">
          <h5>
            주식회사 사각
            <p
              style="margin:0; text-align: left;font-size: 14px;color: #FB5C5C;margin-top: 5px;"
            >
              500 포인트
            </p>
          </h5>
          <button class="" @click="onShare()">공유</button>
        </div>
        <div class="share-detail">
          <h6>데이터 보유 기간</h6>
          <p>2023.02.20 ~ 2024.02.19 <span>(365일)</span></p>
          <h6>목적</h6>
          <p>캔디 사용자 통계 분석용</p>
        </div>

        <h6 style="text-align: left;font-weight: 600;">거래 데이터</h6>
        <ul>
          <li @click="onToggle(1)">
            <span>결제 내역</span>
            <p><span></span></p>
          </li>
          <!--          <li @click="onToggle(2)">-->
          <!--            <span>미디어 파일</span>-->
          <!--            <p><span></span></p>-->
          <!--          </li>-->
          <!--          <li @click="onToggle(3)">-->
          <!--            <span>기타 파일</span>-->
          <!--            <p><span></span></p>-->
          <!--          </li>-->
        </ul>
      </div>
    </div>

    <!-- <div v-else>
      <div
        class="text-center"
        style="width: 95%; text-align: center;max-width: 450px;margin: 30% auto; "
      >
        <p style="font-size: 22px;font-weight: bold;margin-bottom: 5%;">
          네이버페이 결제 내역 불러오기
        </p>
        <a
          v-on:click="naverlogin()"
          href="#"
          style="background: #03C75A;display: block;margin: auto;text-decoration: none;color: #fff;display: flex;height: 45px;border-radius: 6px;overflow: hidden;justify-content: space-between;max-width: 450px;width: 95%;"
          ><img
            height="100%"
            src="https://kr.object.ncloudstorage.com/candiy-bucket/assets/logo/btnG_%EC%95%84%EC%9D%B4%EC%BD%98%EC%82%AC%EA%B0%81.png"
            alt="네이버 로그인"
          />
          <span
            style="display: block;width: 90%;font-size: 15px;line-height: 45px;font-weight: 600;"
            >네이버 로그인</span
          >
        </a>
      </div> 
    </div> -->
  </div>
</template>

<script>
import { mapState } from 'vuex';
import moment from 'moment';

export default {
  name: 'NaverList',
  setup() {},
  data() {
    return {
      isActive: '',
      search: '',
      npay_data_count: 0,
      // saerch_input:"",
      // lineUserId: null
      // clientId: '',
      // clientSecret: '',
      // callbackUri: ''
    };
  },
  filters: {
    dateFormat: function(val, format) {
      return moment(val).format(format);
    },
    toFixed: function(val, num) {
      return parseFloat(val).toFixed(num);
    },
    fileSize: function(val) {
      var x = val * 1024 * 1024;
      var s = ['bytes', 'kB', 'MB', 'GB', 'TB', 'PB'];
      var e = Math.floor(Math.log(x) / Math.log(1024));
      return (x / Math.pow(1024, e)).toFixed(2) + ' ' + s[e];
    },
    comma: function(val) {
      return String(Math.round(val)).replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    },
  },
  computed: {
    ...mapState({
      users: (state) => state.naver.npayData,
      naverUserId: (state) => state.naver.naverUserId,
    }),
    filteredList() {
      return this.users.filter((user) => {
        return user.value.toLowerCase().includes(this.search.toLowerCase());
      });
    },
  },
  created() {
    // if (localStorage.getItem("access_token") !== null) {
    //   var access_token = localStorage.getItem("access_token")
    //
    //   //console.log("access_token:"+access_token)
    //   const {data} = await this.getProfile(access_token)
    //   this.lineUserId = data.userId
    // }

    //console.log("line_user_id:" + this.lineUserId);

    this.$store.dispatch('naver/getNpayData', this.naverUserId);

    if (localStorage.getItem('npay_data_count') != null) {
      this.npay_data_count = localStorage.getItem('npay_data_count');
    }

    //console.log("files:"+JSON.stringify(this.files));
  },
  methods: {
    naverlogin() {
      localStorage.removeItem('naver_access_token');

      this.client_id = process.env.VUE_APP_NAVER_CLIENT_ID;
      this.callbackUrl = process.env.VUE_APP_NAVER_CALLBACK_URL;
      this.state = Math.random()
        .toString(36)
        .substring(2, 16);
      var url =
        'https://nid.naver.com/oauth2.0/authorize?response_type=code&client_id=' +
        this.client_id +
        '&redirect_uri=' +
        this.callbackUrl +
        '&state=' +
        this.state;
      window.location.replace(url);
    },
    typeFilter(type, value) {
      let types = document.querySelectorAll('.types');
      types.forEach((element) => element.classList.remove('on'));

      type.target.classList.add('on');

      // let input_search = document.querySelector("#search_input");
      //
      // input_search.value = value;
      this.search = value;
      console.log(value);
      // some code to filter users
    },
    onShare() {
      let datashare = document.querySelector('.datashare');
      let lineview = document.querySelector('.line-view ');
      if (!datashare.classList.contains('on')) {
        datashare.classList.add('on');
        lineview.style.height = '70vh';
        lineview.style.overflow = 'hidden';
      } else {
        datashare.classList.remove('on');
        lineview.style.height = 'fit-content';
        lineview.style.overflow = 'auto';
      }
    },
    onToggle(idx) {
      let toggle = document.querySelector(
        `.datashare .share ul li:nth-child(${idx}) p`
      );
      toggle.classList.toggle('on');
    },
    play(type, typebox) {
      const media = document.querySelector(`#${type}`);
      // const contBox = document.querySelector(`#${typebox}`);
      console.log(typebox);
      console.log(type);
      // // 마우스 호버 이벤트...fade in/out
      // contBox.addEventListener("mouseleave", function() {
      //   document.querySelectorAll(`#${typebox} span`).forEach((item) => {
      //     item.style.opacity = 0;
      //   });
      // });
      // contBox.addEventListener("mouseenter", function() {
      //   document.querySelectorAll(`#${typebox} span`).forEach((item) => {
      //     item.style.opacity = 1;
      //   });
      // });
      // set the pause button to display:none by default
      // update the progress bar
      if (media.paused) {
        document.querySelector(`#${typebox} .play`).style.display = 'none';
        document.querySelector(`#${typebox} .pause`).style.display =
          'inline-block';
        media.play();
      } else {
        document.querySelector(`#${typebox} .play`).style.display =
          'inline-block';
        document.querySelector(`#${typebox} .pause`).style.display = 'none';
        media.pause();
      }
      media.addEventListener('timeupdate', () => {
        let curr = (media.currentTime / media.duration) * 100;
        if (media.ended) {
          document.querySelector(`#${typebox} .play`).style.display =
            'inline-block';
          document.querySelector(`#${typebox} .pause`).style.display = 'none';
        }
        document.querySelector(`#${typebox} .inner`).style.width = `${curr}%`;
      });
    },
  },
};
</script>
<style>
/* if no content */
.no-content {
  width: 90%;
  margin: auto;
  text-align: center;
  padding-top: 80px;
}
.no-content h2 {
  word-break: keep-all;
  margin-bottom: 10px;
  font-weight: 600;
  color: #191919;
}
.no-content p {
  font-size: 16px;
  color: #666;
  margin-bottom: 60px;
}
.no-content a {
  display: block;
  width: 250px;
  height: 50px;
  line-height: 50px;
  margin: auto;
  margin-bottom: 20px;
  color: #191919;
  display: flex;
  align-items: center;
  border-radius: 7px;
  font-weight: 600;
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.1);
  padding: 0 20px;
}
.no-content a span {
  vertical-align: middle;
  display: block;
  text-align: center;
  width: 100%;
}
.no-content a span.logo {
  display: inline-block;
  height: 30px;
  width: 30px;
  overflow: hidden;
  position: relative;
  font-size: 1rem;
  padding: 0 20px;
}
.no-content a span img {
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}
.no-content a.line {
  background-color: #06c755;
  color: #fff;
}
/*  */
.line-list.container {
  padding: 0;
  width: 95%;
}
/* 타이틀 */
.title-box {
  justify-content: space-between;
  margin: 0 auto;
  padding: 0 5px;
  position: relative;
  width: 93%;
  border-bottom: 1px solid #efefef;
  padding-bottom: 50px;
}
.title-box a {
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  font-size: 18px;
  color: #007bff;
  font-weight: 400;
}
.title-box a svg {
}
.title-box h2 {
  font-size: 18px;
  margin: 0;
  line-height: 6.9444vw;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-weight: 600;
}
/* 공유버튼 */
.title-box .sharebtn {
  margin: 0;
  background: none;
  border: none;
  padding: 0 2.7778vw;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  height: auto;
}
.title-box .sharebtn svg {
  color: #333;
}
/* 필터 */
.filter {
  display: flex;
  width: 90%;
  margin: auto;
  padding: 15px 0px;
  justify-content: space-between;
  max-width: 640px;
  overflow: scroll;
}
/* Hide scrollbar for Chrome, Safari and Opera */
.filter::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.filter {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

/*  */
.filter ul {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-around;
  min-width: fit-content;
  width: 100%;
}
.filter ul li {
  margin-right: 20px;
  display: inline-block;
  white-space: nowrap;
  color: #d2d2d2;
  font-weight: 400;
  font-size: 18px;
}
.filter ul li:last-child {
  margin-right: 0;
}
.filter ul li.on {
  border-color: #5ebdeb;
  color: #5ebdeb;
  font-weight: 900;
}

/* 메세지 보드 */
.msg-wrap {
  margin-top: 3vw;
}
.msg-board {
  margin-top: -0.2778vw;
  --auto-grid-min-size: 340px;
  display: grid;
  grid-template-columns: repeat(
    auto-fill,
    minmax(var(--auto-grid-min-size), 1fr)
  );
}
.msg-board .msg-card {
  width: 92%;
  border: none;
  height: 28vw;
  max-width: 380px;
  min-height: 85px;
  max-height: 100px;
  margin: auto;
  margin-bottom: 10px;
  display: flex;
  border-radius: 10px;
  overflow: hidden;
  background: #fff;
  box-shadow: 3px 3px 9px rgba(0, 0, 0, 0.1);
  padding: 10px;
  box-sizing: border-box;
}
.msg-board .msg-card .thumb {
  height: 100%;
  aspect-ratio: 1/1;
  max-height: 80px;
  position: relative;
  background: #f5f5f5;
  display: block;
  margin-right: 10px;
  overflow: hidden;
  border: 0.6px solid #e5e5e5;
}
.msg-board .msg-card .thumb img {
  width: 100%;
}
.msg-board .msg-card .desc {
  width: 70%;
}

/* 데이터 정보 */
.msg-board .msg-card .desc .pay-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 3px 0;
}
.msg-board .msg-card .desc .pay-info .date {
  display: block;
  height: 100%;
  font-weight: 300;
  color: #a9a9a9;
  font-size: 12px;
}
.msg-board .msg-card .desc .pay-info .status {
  font-weight: 900;
  color: #a3a3a3;
  font-size: 13px;
  margin: 0;
  padding-right: 5px;
}

.msg-board .msg-card .desc .prd-info {
}
.msg-board .msg-card .desc .prd-info .product {
  margin: 0;
  font-size: 15px;
  font-weight: 600;
  width: 100%;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
}
.msg-board .msg-card .desc .prd-info .price {
  font-size: 16px;
  font-weight: 900;
  color: #333;
}

/* 공유하기 */
.datashare {
  position: fixed;
  bottom: 0;
  left: 0;
  transition: 0.5s;
  width: 100%;
  height: 100%;
  opacity: 0;
  pointer-events: none;
  text-align: center;
}
.datashare.on {
  display: block;
  opacity: 1;
  pointer-events: all;
  background: rgba(0, 0, 0, 0.3);
}
.datashare .bg {
  background: transparent;
  width: 100%;
  height: 100%;
}
.datashare .share {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90%;
  background: #fff;
  height: fit-content;
  border-radius: 10px;
  box-shadow: 3px 3px 15px rgba(0, 0, 0, 0.07);
  padding: 30px 30px 40px;
}

.datashare .share h4 {
  line-height: 1.4;
  margin: 0;
  margin-bottom: 1.3889vw;
  font-weight: 600;
  font-size: 18px;
}
.datashare .share > p {
  line-height: 1.2;
  font-size: 15px;
  margin: 0;
  word-break: keep-all;
}
.datashare .share .title-btn {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  border-bottom: 1px solid #999;
  padding: 5px 0;
  margin: 20px 0 20px;
}
.datashare .share .title-btn h5 {
  margin: 0;
  padding: 0;
  font-size: 17px;
  font-weight: 600;
}
.datashare .share .title-btn button {
  margin: 0;
  padding: 3px 0;
  border: none;
  outline: none;
  width: 60px;
  height: fit-content;
  border-radius: 5px;
  background-color: #5ebdeb;
  color: #fff;
  font-weight: 600;
  font-size: 14px;
}
.datashare .share ul {
  padding: 0;
  margin: 0;
  width: 100%;
  margin-top: 5px;
}
.datashare .share ul li {
  margin: auto;
  height: 11.1111vw;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 1.3889vw;
  padding: 0 2.7778vw;
  border: none;
  background: #efefef;
  border-radius: 5px;
}
.datashare .share ul li > span {
  display: block;
  text-align: left;
  font-size: 14px;
}
.datashare .share ul li p {
  display: block;
  width: 11.1111vw;
  background: #999;
  border-radius: 5.5556vw;
  height: 5.5556vw;
  box-sizing: border-box;
  margin-top: 4.1667vw;
  position: relative;
}
.datashare .share ul li p span {
  display: block;
  width: 5vw;
  height: 5vw;
  border-radius: 50%;
  background: #fff;
  position: absolute;
  top: 0.2778vw;
  left: 0.2778vw;
}
.datashare .share ul li p.on {
  background: #00c853;
}
.datashare .share ul li p.on span {
  left: auto;
  right: 0.2778vw;
}
.datashare .share button {
  width: 22.2222vw;
  margin: 5.5556vw auto 0;
}
.share-detail {
  text-align: left;
  margin-bottom: 10px;
}
h6 {
  font-size: 16px;
  color: #333;
  font-weight: 600;
  margin: 0;
}
.share-detail p {
  font-size: 14px;
  color: #666;
  margin-bottom: 20px;
}
.share-detail p span {
  color: #fb5c5c;
}
</style>
